import React, { useEffect, useState } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { useLocation } from "@reach/router";

import { Link } from "gatsby";

const pages = ["events", "djs", "about", "join-the-team", "waiting-list"];

export function Header() {
  const [navOpen, setNavOpen] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    setNavOpen(false);
  }, [pathname]);

  return (
    <nav
      id="header"
      className="relative flex site-header w-full z-30 top-0 text-white"
    >
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-6">
        <div className="pl-4 flex items-center">
          <Link
            activeClassName="active"
            className="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
            to="/"
          >
            <img className="w-8 lg:w-16" src="/logo.png" alt="" />
          </Link>
        </div>
        <div className="block lg:hidden pr-4">
          <button
            id="nav-toggle"
            onClick={() => setNavOpen(!navOpen)}
            className="flex items-center p-1 text-white hover:text-white focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          >
            <svg
              className="fill-current h-6 w-6"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          style={
            navOpen
              ? {
                  display: "flex",
                  top: "var(--site-header-height)",
                  background: "var(--site-header)",
                  left: 0,
                }
              : {}
          }
          className="w-full absolute lg:relative flex-col lg:flex-row lg:flex lg:items-center lg:w-auto hidden lg:mt-0 p-4 lg:p-0 z-20"
          id="nav-content"
        >
          <ul className="list-reset lg:flex justify-end flex-1 items-center">
            {pages.map((p, i) => (
              <li key={i} className="mr-3">
                <Link
                  activeClassName="active"
                  className="inline-block py-2 px-4 font-bold no-underline"
                  to={`/${p}`}
                >
                  {p.toUpperCase().replace(/-/g, " ")}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}
