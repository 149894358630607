import React, { useState } from "react";
import { LazyMotion, domAnimation, m, AnimatePresence } from "framer-motion";
import { Header } from "components/Header";

const durationIn = 0.75;
const durationOut = 0.75;

const variants = {
  cover: {
    x: "0%",
    transition: { duration: durationIn },
  },
  uncover: {
    x: "-100%",
    transition: { duration: durationOut, delay: 0 },
    transitionEnd: {
      x: "100%",
    },
  },
  initial: {
    x: "100%",
  },
};

export default function Layout({ children, path }) {
  const [state, setState] = useState("");
  return (
    <div style={{ position: "relative" }}>
      <Header />
      <LazyMotion features={domAnimation}>
        <AnimatePresence
          initial={false}
          exitBeforeEnter
          onExitComplete={() => setState("entering")}
        >
          <m.div
            layout
            key={path}
            initial={{ opacity: 1 }}
            animate={{
              opacity: 1,

              name: "entering",
              transition: { duration: durationIn },
            }}
            exit={{
              opacity: 1,
              name: "exiting",
              transition: { duration: durationOut },
            }}
            onAnimationComplete={(anim) => {
              setState(anim.name);
            }}
            onAnimationStart={() => {
              if (!state || state === "entering") {
                setState("exiting");
              }
            }}
            style={{ position: "relative" }}
          >
            <AnimatePresence initial={true}>
              <div>{children}</div>
            </AnimatePresence>
          </m.div>
        </AnimatePresence>
        <m.div
          variants={variants}
          initial="initial"
          animate={
            !state ? "initial" : state === "exiting" ? "cover" : "uncover"
          }
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            backgroundColor: "#121212",
          }}
        ></m.div>
      </LazyMotion>
    </div>
  );
}
